import Vue from 'vue'
import Router from 'vue-router'
import Top from '@/components/Top'
import Telework from '@/components/Telework'
import Ttc from '@/components/Ttc'
import Wazao from '@/components/Wazao'
import Happynewyear from '@/components/Happynewyear'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top
    },
    {
      path: '/telework',
      name: 'telework',
      component: Telework
    },
    {
      path: '/ttc',
      name: 'ttc',
      component: Ttc
    },
    {
      path: '/wazao',
      name: 'wazao',
      component: Wazao
    },
    {
        path: '/happynewyear',
        name: 'happynewyear',
        component: Happynewyear
      }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})