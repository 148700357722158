<template>
<div class="wrapper">
  <div class="top">
    <div class="logo">
      <img id="logo" src="../assets/top-logo.svg">
      <img id="arrow" src="../assets/arrow.svg">
    </div>
  </div>
  <div class="box news" style="display: none;">
    <div class="container">
      <div class="heading">News
      </div>
      <div class="articles">
        <div class="row article">
          <div class="thumbnail col-xs-12 col-lg-6">
            <img src="../assets/marutake.jpg"/>
          </div>
          <div class="title col-xs-12 col-lg-6">
            <div class="caption">2021.11.13</div>
            <h1>高知県で竹竿を製作する「マルタケヤ」あらため竿師「翔真」さん工房にお邪魔しました。かつて「ギリ竿」の愛称で親しまれた外通し（ガイド）竿は、高知県で発祥したとされています。</h1>
          </div>
        </div>
        <div class="row article">
          <div class="thumbnail col-xs-12 col-lg-6">
            <img src="../assets/egi.jpg"/>
          </div>
          <div class="title col-xs-12 col-lg-6">
            <div class="caption">2021.10.30</div>
            <h1>初めてのエギング、以降エギングにはまる</h1>
          </div>
        </div>
        <div class="row article">
          <div class="thumbnail col-xs-12 col-lg-6">
            <img src="../assets/sh.png"/>
          </div>
          <div class="title col-xs-12 col-lg-6">
            <div class="caption">2021.10.23</div>
            <h1>スタジオサイタマメンバーの母校、学校法人 佐藤栄学園 栄東中学校生徒向けに、学生時代の友人との起業/地元密着の仕事紹介をテーマにオンライン講義を実施しました。</h1>
          </div>
        </div>
        <a href="https://prtimes.jp/main/html/rd/p/000000001.000087693.html" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/kadomachi.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.10.11</div>
              <h1>大宮東口エリア初の再開発事業に参画、「大宮門街」のプレスリリース執筆、発信を行いました。スタジオサイタマは2021年6月より、大宮門街開業支援としてPR・ブランディング推進に従事しております。</h1>
            </div>
          </div>
        </a>
        <div class="row article">
          <div class="thumbnail col-xs-12 col-lg-6">
            <img src="../assets/test1.jpg"/>
          </div>
          <div class="title col-xs-12 col-lg-6">
            <div class="caption">2021.09.18-26</div>
            <h1>北海道に「渓流ルアー和竿」のテスト釣行に行きました。約一週間の間渓流ルアー和竿の耐久テスト含め、さらなるブラッシュアップに向けて鍛錬しました。</h1>
          </div>
        </div>
        <div class="row article">
          <div class="thumbnail col-xs-12 col-lg-6">
            <img src="../assets/ws2.jpg"/>
          </div>
          <div class="title col-xs-12 col-lg-6">
            <div class="caption">2021.08.22</div>
            <h1>埼玉伝統工芸会館にて、竹釣竿製作ワークショップを実施。職人の作業工程をわかりやすく説明するビデオ教材を制作、活用しました。</h1>
          </div>
        </div>
        <a href="https://www.atlia.jp/ws_lecture/archive/#ws20210814" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/ws1.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.08.14-15</div>
              <h1>川口市立アートギャラリー・アトリアにて、竹釣竿製作ワークショップを実施。職人の作業工程をわかりやすく説明するビデオ教材を制作、活用しました。</h1>
            </div>
          </div>
        </a>
        <a href="https://www.facebook.com/tabichoco/posts/4270983236318522/" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/chokotabi.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.08.13</div>
              <h1>ちょこたび埼玉さまに埼玉の渓流・和竿の写真素材をご提供。公式Instagram・Facebookページにて掲載いただきました。県内の渓流釣りスポットと「和竿」が紹介されています。</h1>
            </div>
          </div>
        </a>
        <div class="row article">
          <div class="thumbnail col-xs-12 col-lg-6">
            <img src="../assets/test2.jpg"/>
          </div>
          <div class="title col-xs-12 col-lg-6">
            <div class="caption">2021.06.24-26</div>
            <h1>静岡県に「渓流ルアー和竿」のテスト釣行に行きました。小渓での和竿の扱い、延べ竿での鮎のドブ釣りを探求。また、代表社員が左膝内側側副靭帯損傷しました。</h1>
          </div>
        </div>
        <a href="https://www.facebook.com/events/327030629015463/" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/saiji2.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.06.01-06</div>
              <h1>【WAZAO-IPPONポップアップ】上野の東京文化会館にてポップアップストアを開催、渓流ルアー和竿の完成品の展示、オーダーのご相談受け付けを行いました。</h1>
            </div>
          </div>
        </a>
        <a href="https://prtimes.jp/main/html/rd/p/000000009.000056466.html" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/lure-wazao.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.05.31</div>
              <h1>「WAZAO-IPPON」にて、和竿の世界観をルアーフィッシングで楽しめる「渓流ルアー和竿」の受注生産を開始。上野東京文化会館にて6/1(火)より完成品を展示。</h1>
            </div>
          </div>
        </a>
        <a href="https://prtimes.jp/main/html/rd/p/000000008.000056466.html" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/seto-rhino.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.04.30</div>
              <h1>【全長270cmのキタシロサイ】瀬戸優氏による彫刻作品「水月〜シロサイ〜」がスタジオサイタマおよび埼玉県を象徴する存在となりました。</h1>
            </div>
          </div>
        </a>
        <a href="https://www.facebook.com/events/583842782596319/" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/saiji1.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.04.20-25</div>
              <h1>【WAZAO-IPPONポップアップ】埼玉県伝統工芸会館にて、埼玉県釣竿工業連合組合による「実演と販売」催事サポートを実施しました。</h1>
            </div>
          </div>
        </a>
        <a href="https://prtimes.jp/main/html/rd/p/000000007.000056466.html" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/wazao-add-product.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.03.31</div>
              <h1>日本独自の伝統釣竿、和竿特化のオンラインストア「WAZAO-IPPON」商品ラインナップを大幅拡充</h1>
            </div>
          </div>
        </a>
        <div class="row article">
          <div class="thumbnail col-xs-12 col-lg-6">
            <img src="../assets/chef.jpg"/>
          </div>
          <div class="title col-xs-12 col-lg-6">
            <div class="caption">2021.03.15</div>
            <h1>川口市教育委員会様の地域小学校向けの取り組み「給食センターライブ放送」の配信支援を実施しました。</h1>
          </div>
        </div>
        <a href="https://prtimes.jp/main/html/rd/p/000000005.000056466.html" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/branding-sodan.png"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.02.28</div>
              <h1>ブランディングの次のアクションを明確化、即実行段階に移すための新サービス「ブランディング相談所」提供開始！</h1>
            </div>
          </div>
        </a>
        <router-link to="/wazao">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/wazao.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2021.01.15</div>
              <h1>日本独自の伝統釣竿「和竿」を軸とした釣り事業、および和竿特化のオンラインストア「WAZAO-IPPON」がオープン</h1>
            </div>
          </div>
        </router-link>
        <router-link to="/ttc">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/ttc.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2020.12.20</div>
              <h1>埼玉県の伝統産業「和竿」と釣り文化を学ぶ、伝統文化体験型プログラム「つくって、つって、くらって」を提供開始。</h1>
            </div>
          </div>
        </router-link>
        <router-link to="/telework">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/tellkato.png"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2020.5.14</div>
              <h1>埼玉県内事業者のテレワーク導入支援を電話一本、無料で行う新サービス「テレワークかとちゃん」開始</h1>
            </div>
          </div>
        </router-link>
        <a href="https://note.com/studiosaitama/n/n852919bf1531" target="_blank">
          <div class="row article">
            <div class="thumbnail col-xs-12 col-lg-6">
              <img src="../assets/note.jpg"/>
            </div>
            <div class="title col-xs-12 col-lg-6">
              <div class="caption">2020.4.28</div>
              <h1>なぜ埼玉か、スタジオサイタマとはなんなのか。スタジオサイタマの決意表明をnoteに書きました。</h1>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="box about">
    <div class="container">
      <div class="heading">About
      </div>
    </div>
    <div class="color-bg">
      <div class="blue">
      </div>
      <div class="white">
        <div class="about-body">
          <div class="thumbnail">
            <img src="../assets/about.png"/>
          </div>
          <div class="about-title">こりかたまった存在を賞賛する</div>
          <p>スタジオサイタマは埼玉県を拠点にICT/クリエイティブを駆使し、”オルタナティブ”の文化醸成を目指す、青春取り戻しカンパニーです。不合理、意味のないもの、目的の不在、それらは現代社会において排除されてしまいがちですが、そのような価値観の中にこそ、人々の原始的な喜びや熱狂=青春があると考えています。日頃は日本の伝統的な釣り竿である「和竿」に特化した釣りブランド「WAZAO-IPPON」の運営を主に、埼玉県の隠された価値の発掘、地域事業者様支援などを行っております。</p>
        </div>
      </div>
    </div>
  </div>
  <div class="box sakana">
    <div class="container">
      <div class="heading">SAKANA SUGOI SAITAMA
      </div>
      <div class="row">
        <div class="article-card col-md-4 col-lg-4 col-xs-12">
          <div class="thumbnail-bg">
            <a href="https://wazao-ippon.com/" target="_brank" >
              <img src="../assets/wazao.jpg"/>
              <img class="more" src="../assets/more.svg"/>
            </a>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">WAZAO-IPPON</div>
            <p>日本独自の伝統釣竿「和竿」を軸とした釣り事業、および和竿特化のオンラインストア「WAZAO-IPPON」</p>
          </div>
        </div>
        <div class="article-card col-md-4 col-lg-4 col-xs-12" style="display: none;">
          <div class="thumbnail-bg">
            <router-link to="/ttc">
              <img src="../assets/ttc.jpg"/>
              <img class="more" src="../assets/more.svg"/>
            </router-link>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">「つくって、つって、くらって」</div>
            <p>埼玉県伝統工芸士である和竿師による竹を用いた釣竿製作体験、遊休スペース化した小学校のプールを活用した釣り体験、食育体験をパッケージとして提供</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box sauna">
    <div class="container">
      <div class="heading">SAUNA SUGOI SAITAMA
      </div>
      <div class="row">
        <div class="article-card col-md-4 col-lg-4 col-xs-12">
          <div class="thumbnail-bg">
            <a href="https://shinsen.sugoisaitama.com/" target="_blank">
              <img src="../assets/works01.png"/>
              <img class="more" src="../assets/more.svg"/>
            </a>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">ツカノマノスゴイサウナ</div>
            <p>「渋谷にサイタマを創る」とのコンセプトで開催した期間限定サウナイベント。渋谷区神泉のビル屋上で週末限定4ヶ月間実施。700名以上を動員</p>
          </div>
        </div>
        <div class="article-card col-md-4 col-lg-4 col-xs-12">
          <div class="thumbnail-bg">
            <a href="https://sauna.sugoisaitama.com/" target="_blank">
              <img src="../assets/works02.png"/>
              <img class="more" src="../assets/more.svg"/>
            </a>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">SAUNA MAGIC</div>
            <p>埼玉県が誇る国内有数のサウナ施設 「草加健康センター」の知られざる裏側に迫る ファン垂涎のブログ記事サイト</p>
          </div>
        </div>
        <div class="article-card col-md-4 col-lg-4 col-xs-12">
          <div class="thumbnail-bg">
            <img src="../assets/works04.jpg"/>
            <img class="more" src="../assets/more.svg"/>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">おうちで草加健康センター</div>
            <p>草加健康センターの360度画像アーカイブ作品 撮影した映像を元にフォトグラメトリーや3Dモデリングを作成、公開。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="box sodan">
    <div class="container">
      <div class="heading">SODAN SUGOI SAITAMA
      </div>
      <div class="row">
        <div class="article-card col-md-4 col-lg-4 col-xs-12">
          <div class="thumbnail-bg">
            <a href="https://sodan.sugoisaitama.com/" target="_brank" >
              <img src="../assets/branding-sodan.png"/>
              <img class="more" src="../assets/more.svg"/>
            </a>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">ブランディング相談所</div>
            <p>なんか必要そうだけど、実はよくわからないブランディング。そんな事業者の皆様に向けて、診断と簡単なプランの提示をすることで、誰もがブランドのツノを磨くことができるように、サポートするための支援サービスです。</p>
          </div>
        </div>
        <div class="article-card col-md-4 col-lg-4 col-xs-12">
          <div class="thumbnail-bg">
            <router-link to="/telework">
              <img src="../assets/works03.png"/>
              <img class="more" src="../assets/more.svg"/>
            </router-link>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">テレワークかとちゃん</div>
            <p>埼玉県内の事業者向けテレワーク支援サービス。 県内事業者であれば誰でも、企業内内線感覚で テレワークの導入相談ができる無料サービス</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box sai">
    <div class="container">
      <div class="heading">SAI
      </div>
      <div class="row">
        <div class="article-card col-md-4 col-lg-4 col-xs-12">
          <div class="thumbnail-bg">
            <a href="https://prtimes.jp/main/html/rd/p/000000008.000056466.html" target="_blank">
              <img src="../assets/seto-rhino.jpg"/>
              <img class="more" src="../assets/more.svg"/>
            </a>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">彫刻家・瀬戸優氏作「水月〜シロサイ〜」</div>
            <p>当社・ひいては埼玉県のシンボルとして、本作品を通じた価値の提供に挑戦。</p>
          </div>
        </div>
        <div class="article-card col-md-4 col-lg-4 col-xs-12">
          <div class="thumbnail-bg">
            <a href="https://prtimes.jp/main/html/rd/p/000000001.000056466.html" target="_blank">
              <img src="../assets/works05.jpg"/>
              <img class="more" src="../assets/more.svg"/>
            </a>
          </div>
          <div class="article-card-body">
            <div class="article-card-title">イイねを押すと増えるサイ</div>
            <p>インスタグラム上で行うサイの保護活動啓発コンテンツ（バーチャルコンサベーションプログラム）。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box members">
    <div class="container">
      <div class="heading">Team
      </div>
      <div class="color-bg">
        <div class="row">
          <div class="article-card col-md-4 col-lg-4 col-xs-12">
            <div class="thumbnail-bg">
              <img src="../assets/illhhd.png"/>
            </div>
            <div class="article-card-body">
              <div class="article-card-title">CHIAKI KATO
                エンジニア・ディレクター</div>
              <p>慶應義塾大学卒業後、株式会社NTTデータ入社。NTTデータにて大規模システム開発を経験後、理化学研究所発VRベンチャーハコスコにて開発部長/プロダクトマネージャとしてVR関連新規技術、事業開発に従事。</p>
            </div>
          </div>
          <div class="article-card col-md-4 col-lg-4 col-xs-12">
            <div class="thumbnail-bg">
              <img src="../assets/bujiro.png"/>
            </div>
            <div class="article-card-body">
              <div class="article-card-title">TOMOHIRO SOENO
                デザイナー・プランナー</div>
              <p>慶應義塾大学卒業後、株式会社博報堂入社。ブランド戦略部門にて事業/商品価値の整理を軸に、ブランドガイドラインやCI/VIの設計~実施までに携わる。官公庁のブランディング事業もサポート。</p>
            </div>
          </div>
          <div class="article-card col-md-4 col-lg-4 col-xs-12">
            <div class="thumbnail-bg">
              <img src="../assets/masi-g-row.png"/>
            </div>
            <div class="article-card-body">
              <div class="article-card-title">MASI-G-ROW
                営業・営業企画</div>
              <p>慶應義塾大学卒業後、みずほ銀行に入社。後、国内大手グルメサービスを運営するスタートアップ企業で営業・事業企画として従事。</p>
            </div>
          </div>
          <div class="article-card col-md-4 col-lg-4 col-xs-12">
            <div class="thumbnail-bg">
              <img src="../assets/gunso.jpg"/>
            </div>
            <div class="article-card-body">
              <div class="article-card-title">T.I
                PM・BizDev</div>
              <p>慶應義塾大学卒業後、大手外資系IT企業に入社。ITコンサルタントとして業務改善、システム導入のコンサルティングの提案からデリバリーまでを推進。特に人事領域のコンサルティングを強みとし、タレントマネジメントや、人材組織管理・給与・勤怠などの基幹業務システムのプロジェクトに従事した。パッケージ商品やサービスの企画・開発も手がけた。</p>
            </div>
          </div>
          <div class="article-card col-md-4 col-lg-4 col-xs-12">
            <div class="thumbnail-bg">
              <img src="../assets/aono.jpeg"/>
            </div>
            <div class="article-card-body">
              <div class="article-card-title">SHINZABURO AONO
                マーケター</div>
              <p>慶應義塾大学卒業後Google株式会社入社。グーグル日本法人では、金融、求人、Eコマース関連など数多くの業界の企業約1000社のオンラインマーケティングを支援。アジア太平洋地域の広告プロダクトスペシャリストとして、ショッピング広告の普及に努め、数多くの講演を行った。</p>
            </div>
          </div>
          <div class="article-card col-md-4 col-lg-4 col-xs-12">
            <div class="thumbnail-bg">
              <img src="../assets/mura.jpeg"/>
            </div>
            <div class="article-card-body">
              <div class="article-card-title">MASANARI MURAMOTO
                プロデューサー</div>
              <p>2016年、オーストラリアから帰国後、国内ショールームにて活動。MAN/WOMANへの出展、マーチャンダイズ、ディストリビューションの経験を経て、2018年よりクリエイティブエージェンシーのブランドディレクターとして活動を開始。ファッションやスポーツ、音楽などの知見と経験を背景に、グローバルブランドの戦略設計からコンセプトメイキング、コンテンツの開発まで、統合的にプロデュース。2020年、CEKAIへ参画。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box contact">
    <div class="container">
      <div class="contacts">
        <a href="mailto:saitama@studiosaitama.com">saitama@studiosaitama.com</a>
        <div class="social-icons">
          <div class="social-icon">
            <a href="https://twitter.com/saunasugoisai" target="_blank">
              <img class="more" src="../assets/Twitter.svg"/>
            </a>
          </div>
          <div class=social-icon>
            <a href="https://www.instagram.com/wazaoippon/" target="_blank">
              <img class="more" src="../assets/Instagram.svg"/>
            </a>
          </div>
          <div class=social-icon>
            <a href="https://www.facebook.com/studiosaitama" target="_blank">
              <img class="more" src="../assets/Facebook.svg"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  metaInfo: {
    title: 'スタジオサイタマ合同会社',
    meta: [
      { name: 'keywords', content: '埼玉,スタジオサイタマ,スタジオ埼玉,ツカノマノスゴイサウナ,和竿,和竿一本,wazao,wazaoippon,wazao-ippon,釣り,web制作,デザイン,サウナ,サウナスゴイサイタマ' },
      { name: 'description', content: '埼玉のことならなんでもお任せ、スタジオサイタマ。' },
      { property: 'og:title', content: 'スタジオサイタマ合同会社' },
      { property: 'og:description', content: '埼玉のことならなんでもお任せ、スタジオサイタマ。' },
      { property: 'og:site_name', content: 'スタジオサイタマ合同会社' },
      { property: 'og:url', content: 'https://studiosaitama.com' },
      { property: 'og:image', content: 'https://studiosaitama.com/static/og.png' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'スタジオサイタマ合同会社' },
      { name: 'twitter:url', content: 'https://studiosaitama.com' },
      { name: 'twitter:description', content: '埼玉のことならなんでもお任せ、スタジオサイタマ。' },
      { name: 'twitter:image', content: 'https://studiosaitama.com/static/og.png' }
    ]
  }
}
</script>

<style scoped>
.wrapper {
  background-color: #004680;
  padding-bottom: 164px;
}
.top {
  background: url(../assets/top_rhino.jpg) no-repeat 69% center;/* 画像（ここにfixedは入れない） */
  background-size: cover;/* cover指定できる */
  width: 100%;/* 横幅いっぱい */
  height: 100vh;/* 縦幅いっぱい */
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.logo {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
}
img#logo {
  max-width: 100%;
}
.box.sakana {
  background-image: url(../assets/sakana.jpg);
  background-position: center;
}
.box.sauna {
  background-image: url(../assets/sauna.jpg);
  background-position: center;
}
.box.sodan {
  background-image: url(../assets/sodan.jpg);
  background-position: center;
}
.box.sai {
  background-image: url(../assets/seto-rhino.jpg);
  background-position: center;
  background-size: cover;
}
.box.contact {
  padding-top: 200px;
}
.container {
  padding-right: 8px;
  padding-left: 8px;
}
.about {
  background: #FFEAD8;
  text-align: -webkit-center;
  height: 1031px;
}
.about .heading {
  color: #004680;
}
.color-bg {
  position: relative;
  max-width: 900px;
}
.color-bg .blue {
  position: absolute;
  background-color: #004680;
  width: 90%;
  height: 431px;
  box-shadow: 3px 3px 6px #00000029;
}
.color-bg .white {
  position: absolute;
  background-color: #FFFFFF;
  width: 90%;
  height: 431px;
  top: 40px;
  right: 0px;
  box-shadow: 3px 3px 6px #00000029;
}
.color-bg .about-body {
  text-align: left;
  width: 90%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-family: 'Montserrat', sans-serif;
  color: #3B4043;
}
.color-bg .about-body p{
  padding-top: 9px;
  font-size: 13px;
  font: 13px/20px Noto Sans JP;
}
.color-bg .about-title {
  padding-bottom: 3px;
  font-size: 16px;
  font: Bold 16px/23px Noto Sans JP;
  font-weight: bold;
}
.about-body .thumbnail {
  padding-top: 20px;
  padding-bottom: 20px;
}
.heading {
  font-family: 'Montserrat', sans-serif;
  font: Bold 65px/72px 'Montserrat';
  font-size: 65px;
  font-weight: bold;
  color: #FFEAD8;
  padding-top: 130px;
  padding-bottom: 70px;
  text-align: center;
}
.articles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.articles .row {
  margin-right: 0px;
  margin-left: 0px;
}
.articles img {
  width: 100%;
}
.article {
  margin-bottom: 37px;
}
.article .title {
  min-height: 173px;
  color: #FFEAD8;
  border: 3px solid #FFEAD8;
}
.thumbnail img{
  width: 100%;
}
.article .thumbnail {
  padding: 0px;
  max-height: 179px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article .caption {
  font-family: 'Montserrat', sans-serif;
  font: Medium 16px/68px 'Montserrat';
  padding-bottom: 6px;
  padding-top: 10px;
}
.article-card {
  text-align: center;
  text-align: -webkit-center;
}
.article-card .thumbnail-bg {
  background: #FFEAD8;
  padding-top: 2%;
  padding-bottom: 2%;
  position: relative;
  width: 90%;
}

.article-card .thumbnail-bg .more{
  right: 0px;
  bottom: 0px;
  position: absolute;
  width: 30%;
}
.article-card img {
  width: 95%;
}
.article-card-body {
  text-align: left;
  width: 90%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-family: 'Montserrat', sans-serif;
  color: #FFEAD8;
}
.article-card-title {
  border-bottom: 3px solid #FFEAD8;
  padding-bottom: 3px;
  font-size: 16px;
  font: Bold 16px/23px Noto Sans JP;
  font-weight: bold;
}
.members .article-card .thumbnail-bg {
  background: #004680;
}
.members .article-card-title {
  border-bottom: 3px solid #004680;
}
.members .article-card-body {
  color: #004680;
}
.article-card-body p{
  padding-top: 9px;
  font-size: 13px;
  font: 13px/20px Noto Sans JP;
}
.article h1 {
  font: Bold 19px/30px Noto Sans JP;
  padding-bottom: 5px;
}
.members {
  background: #FFEAD8;
  text-align: -webkit-center;
}
.members .heading {
  color: #004680;
}
.contact .container {
  display: flex;
  justify-content: center;
}
.contacts {
  max-width: 446px;
}
.contacts a{
  font: Bold 22px/50px Source Han Sans JP;
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: bold;
  color: #FFEAD8;
}
.social-icons {
  display: flex;
  justify-content: flex-end;
}
.social-icon {
  padding-left: 26px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.social-icon img {
  width: 86%;
}

.container .row {
  margin-left: 0px;
  margin-right: 0px;
}
img#arrow {
  max-height: 100px;
}
@media screen and (min-width:480px) {
  img#logo {
    max-width: 100%;
  }
  img#arrow {
    padding-top: 37.5px;
  }
}
@media screen and (min-width:1200px) {
  .logo {
    padding-left: 200px;
  }
  .about {
    height: 100vh;
  }
  .box.sakana {
    height: 100vh;
  }
  .box.sauna {
    height: 100vh;
  }
  .box.sodan {
    height: 100vh;
  }
  .box.sai {
    height: 100vh;
  }
}
@media screen and (max-width:480px) {
  img#arrow {
    position: absolute;
    bottom: 3%;
    right: 50%;
  }
}
</style>
