<template>
  <div id="app">
     <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    'Footer': Footer
  }
}
</script>

<style>
#app {
  font: W6 25px/40px Hiragino Mincho ProN;
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", sans-serif;
  color: #2c3e50;
  letter-spacing: 0.8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: pre-line;
}
a:hover {
  text-decoration: none;
}
</style>
