<template>
  <div class="wrapper">
    <div class="image">
      <img src="../assets/ttc.jpg">
    </div>
    <div class="article-container">
      <div class="caption">学校を利用した釣竿製作・釣り体験プログラム提供開始のお知らせ
      </div>
      <h1 class="title">埼玉県の伝統産業「和竿」と釣り文化を学ぶ、伝統文化体験型プログラム「つくって、つって、くらって」を提供開始。</h1>
      <div class="content txt">
        <b>「つくって、つって、くらって」概要</b>
        <p>本プログラムは、埼玉県伝統工芸士である和竿師による竹を用いた釣竿製作体験、遊休スペース化した小学校のプールを活用した釣り体験、食育体験をパッケージとして地域の子どもたちおよび親子等に提供するものです。</p>
        <p>本記事ではプログラムの詳細を、12月に実施したイベントの様子と共にご案内いたします。</p>
        <p>本プログラムは4つに分かれる構成になっています。プログラムは実施要望や条件に応じた拡大や縮小が可能です。</p>
        <p>1.「しって」：日本の釣り文化や和竿について学ぶオンライン事前学習
          2.「つくって」：和竿の職人と行う、オリジナル竹釣竿作り
          3.「つって」：学校プールを活用した、ニジマス釣り
          4.「くらって」：学校グラウンドで行う調理、BBQ
        </p>
        <b>プログラム詳細</b><br>
        <b>「しって」</b>
        <p>オンラインにて事前学習コンテンツをライブ配信<br>実際のイベントに先立ち、オンラインによる事前学習コンテンツをライブ配信しました。当日は山野和竿店の工房よりライブ中継を行い、和竿師による和竿の歴史、種類や製作風景の説明をはじめ、子供たちからの質疑応答などを実施しました。 </p>
        <div class="video">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/BGOVzYKcVt0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <b>「つくって」</b>
        <p>埼玉県川口市の伝統産業である「和竿」の職人と行う、オリジナル竹釣竿作り体験</p>
        <p>イベント当日は和竿師の指導の元、竹を使用した釣竿製作体験を行いました。実際の和竿製作工程である竹材の加工や糸巻き、塗装を行うことで、植物である竹が釣竿に変化していく過程を体験できるとともに、世界で一本だけのオリジナルロッドを製作しました。</p>
        <div class="image">
          <img src="../assets/ttc_01.jpg">
        </div>
        <div class="video">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/FoQxcz9M14w" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <b>「つって」</b>
        <p>自分の作った竿でニジマスを釣る、プールフィッシング体験</p>
        <p>竹釣竿を使用して、学校のプールでニジマス釣り体験を行いました。自作した竹釣竿に仕掛けやエサをつけてニジマスを釣り上げることにより、竹釣竿の繊細さ、しなりの強さを実感。自然の生命とのふれあいを通じた学びや釣りそのものの楽しみも感じていただきました。</p>
        <div class="image">
          <img src="../assets/ttc_02.jpg">
        </div>
        <b>「くらって」</b>
        <p>釣った魚を自ら捌き、炭火で調理する場も提供<br>（イベント終了後、希望者のみ実施）</p>
        <p>釣った後にはグラウンドを使用したニジマスの調理、炭火焼を行いました。自作した竹釣竿で釣り上げるだけでなく、その生命を"シメて"調理し、"食す”までの一連のプロセスを通じて、伝統産業、文化理解と共に、食することを通じた学びを提供しました。</p>
        <div class="image">
          <img src="../assets/ttc_03.jpg">
        </div>
        <b>プログラム実施の背景</b>
        <p>江戸時代、庶民の文化として定着した「釣り」。</p>
        <p>埼玉県川口市周辺は、日本古来の伝統的な釣竿である「和竿」に適している布袋竹が多く自生していた地域であったため、全国に流通する和竿の生産地として多くの和竿師を抱えた地域でした。</p>
        <p>昨今は釣り製品の工業化に伴い需要が減少傾向にある「和竿」ですが、自然由来の素材を活用した釣りのスタイルや竹材ならではの釣り味は、今もなお多くのファンを魅了し続けています。</p>
        <p>一方で伝統産業の継承を巡っては、職人の減少や高齢化、地域での認知率の減少などが課題となっており、地域と協力した認知・理解の場の創出が重要になっています。</p>
        <p>加えて新型コロナウイルスの感染拡大により、地域内の親子世帯では子供の外出・体験学習機会が減少していることが地域課題となっています。本プログラムは通い慣れた小学校にて実施ができる特性上、本課題に対しても有用な施策であると考えています。</p>
        <b>埼玉県の伝統資産である、和竿や釣り文化の更なる発展を目指して</b>
        <p>スタジオサイタマでは「釣り文化」を埼玉県における重要産業と位置付けています。</p>
        <p>理由として、埼玉県は、<br>・江戸を中心とした釣り文化を支えた和竿の生産地である<br>・荒川や利根川に代表される関東屈指の大型河川がある<br>・県土に占める河川面積割合が日本一であり、水辺空間が豊富な「川の県」である<br>・国内屈指の釣具メーカー「上州屋」や「マルキュー」本社をはじめ、日本最古のリールメーカーともいわれる「五十鈴工業」（※2020年移転）など多くの釣り関連企業が所在する<br>などの事実に加え、県内外へのアクセスの良さから海・山・川全てのジャンルの釣り文化が深く根付いていることが挙げられます。</p>
        <p>また、昨今のアウトドアブームや河川敷で行うテントサウナのブームなど、自然体験型アクティビティの潮流拡大や新型コロナウイルス感染拡大によるソーシャルディスタンスの確保などの問題と釣りの親和性は高く、今後大きなニーズが見込まれると考えています。</p>
        <p>以上を踏まえ、スタジオサイタマでは弊社メンバーの豊富な企画力や遊休資産活用ノウハウ、体験提供実績などを元に、埼玉県発の新しい釣りスタイルの発信や釣りとの接点の創出を行ってまいります。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: '釣竿製作・釣り体験プログラム「つくって、つって、くらって」 | スタジオサイタマ合同会社',
    meta: [
      { name: 'keywords', content: '釣り,埼玉,伝統文化,スタジオサイタマ' },
      { name: 'description', content: '埼玉県の伝統産業「和竿」と釣り文化を学ぶ、伝統文化体験型プログラム「つくって、つって、くらって」' },
      { property: 'og:title', content: '釣竿製作・釣り体験プログラム | スタジオサイタマ合同会社' },
      { property: 'og:description', content: '埼玉県の伝統産業「和竿」と釣り文化を学ぶ、伝統文化体験型プログラム「つくって、つって、くらって」' },
      { property: 'og:site_name', content: '釣竿製作・釣り体験プログラム | スタジオサイタマ合同会社' },
      { property: 'og:url', content: 'https://studiosaitama.com/ttc' },
      { property: 'og:image', content: 'https://studiosaitama.com/static/ttc.jpg' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: '釣竿製作・釣り体験プログラム「つくって、つって、くらって」 | スタジオサイタマ合同会社' },
      { name: 'twitter:url', content: 'https://studiosaitama.com/ttc' },
      { name: 'twitter:description', content: '埼玉県の伝統産業「和竿」と釣り文化を学ぶ、伝統文化体験型プログラム「つくって、つって、くらって」' },
      { name: 'twitter:image', content: 'https://studiosaitama.com/static/ttc.jpg' }
    ]
  }
}
</script>

<style scoped>
.wrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.article-container {
  padding: 7%;
}
.article-container .caption {
  font: 14px/24px Noto Sans JP;
  padding-bottom: 6px;
}
.article-container .title {
  font: Bold 22px/30px Noto Sans JP;
  border-bottom: 1px solid black;
  padding-bottom: 15px;
}
.article-container .content {
  font: Normal 15px/26px Noto Sans JP;
}
.content {
  padding-top: 10px;
}
.content .video {
  text-align: center;
}
.content img {
  padding-bottom: 17px;
}
img {
  width: 100%;
}
.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.share {
  font-weight: bold;
  border-bottom: 2px solid black;
}
.sns-items {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
