<template>
  <div class="wrapper">
    <div class="image">
      <img src="../assets/happynewyear2022.jpg">
      <img src="../assets/saibo.gif">
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'スタジオサイタマの軌跡2022 | スタジオサイタマ合同会社',
    meta: [
      { name: 'keywords', content: 'スタジオサイタマの軌跡,埼玉,スタジオサイタマ,ツカノマノスゴイサウナ,WAZAO-IPPON,WAZAO,和竿,釣り,大宮門街' },
      { name: 'description', content: '新年あけましておめでとうごザイます！サイタマの一本ヅノ足るべく2020年にTOKYOから荒川を越えてSAITAMAへ。サウナに始まり釣りに目覚めた、スタジオサイタマの突進の軌跡をドドドドド〜っと振り返るよ〜！！' },
      { property: 'og:title', content: 'スタジオサイタマの軌跡2022 | スタジオサイタマ合同会社' },
      { property: 'og:description', content: '新年あけましておめでとうごザイます！サイタマの一本ヅノ足るべく2020年にTOKYOから荒川を越えてSAITAMAへ。サウナに始まり釣りに目覚めた、スタジオサイタマの突進の軌跡をドドドドド〜っと振り返るよ〜！！' },
      { property: 'og:site_name', content: 'スタジオサイタマの軌跡2022 | スタジオサイタマ合同会社' },
      { property: 'og:url', content: 'https://studiosaitama.com/happynewyear' },
      { property: 'og:image', content: 'https://studiosaitama.com/static/nenga.jpg' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'スタジオサイタマの軌跡2022 | スタジオサイタマ合同会社' },
      { name: 'twitter:url', content: 'https://studiosaitama.com/happynewyear' },
      { name: 'twitter:description', content: '新年あけましておめでとうごザイます！サイタマの一本ヅノ足るべく2020年にTOKYOから荒川を越えてSAITAMAへ。サウナに始まり釣りに目覚めた、スタジオサイタマの突進の軌跡をドドドドド〜っと振り返るよ〜！！' },
      { name: 'twitter:image', content: 'https://studiosaitama.com/static/nenga.jpg' }
    ]
  }
}
</script>

<style scoped>
.wrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

img {
  width: 100%;
}
</style>
