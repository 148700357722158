<template>
  <div class="wrapper">
    <div class="image">
      <img src="../assets/tellkato.png">
    </div>
    <div class="article-container">
      <div class="caption">テレワーク導入支援開始のお知らせ
      </div>
      <h1 class="title">埼玉県内事業者のテレワーク導入支援を電話一本、無料で行う新サービス「テレワークかとちゃん」開始</h1>
      <div class="content txt">
        <b>「テレワークかとちゃん」概要</b>
        <p>本サービスは、記載されている弊社代表加藤の個人携帯番号に電話するだけで、いつでも（埼玉県内の）誰でもテレワークに関する質問をすることができる直通相談サービスです。</p>
        <p>さいたま市内の運輸会社さまへのテレワーク導入実績で得た知見、過去3年間のフルリモートワーク環境下での業務経験を元に、一社ごとに異なる社内文化や業務内容を理解したうえで、適切なツールの選択から導入範囲の特定、人材の育成計画まで、私たちでできることは全てサポートさせていただきます。</p>
        <p>「社内に詳しい人間がいない」、「ちょっとテレワークの質問をできる相手が欲しかった」などの動機づけで構いませんので、<strong>身構えることなくぜひ内線感覚でお電話ください。</strong></p>
        <p>※基本的には埼玉県内の事業者さま限定とさせていただきます。<br>※本気でやっておりますので、不要不急のお電話はお控えください。</p>
        <b>利用方法</b>
        <p>「簡単な2ステップ」
          1. テレワークに関してなんでもいいので疑問を持つ
          2. かとちゃんに電話する
        </p>
        <div class="image">
          <img src="../assets/howto.png">
          <a href="tel:080-3490-5892">
            <img src="../assets/tellme.png">
          </a>
        </div>
        <b>サービス開始の背景</b>
        <p>私たちスタジオサイタマ合同会社は地元埼玉県のために生きることを決意し、都内の会社を卒業したメンバーで2019年に立ち上げました。</p>
        <p>日頃は自社事業のほか、埼玉県内各事業者さまの事業相談やWeb制作、サービス・コンテンツ開発の支援を行っております。</p>
        <p>その中で、昨今の新型コロナウイルス流行に伴い、多くの事業者さまがテレワークについて悩んでいることに直面する機会が多くありました。</p>
        <p>3月から4月にかけて、埼玉県内事業者のテレワーク導入率は、13.8%から34.2%へと大幅に増加したとの統計（パーソル総合研究所調べ） の一方で、埼玉県内事業者さまとお話しすると、業務の中心が紙の資料や内線を使った社内コミュニケーションであることなどを理由に、「そもそもテレワーク化できる業務がない」というお話を聞く機会が多くあります。</p>
        <p>また、ローカルカンパニーには各社ごと個別の社風や独自の業務スタイルが定着していることから、「国のガイドラインだけでは必要な情報が足りていない、、」「でもわざわざコンサルティングサービスを外部に依頼するのも、、、」という状況が多く発生していることに着目いたしました。</p>
        <p>それならば、埼玉のみんなのために、<strong>誰でも簡単に使える遠隔社内部署のような仕組みを導入する</strong>ことで、埼玉県内事業者さま、ひいては埼玉県全体の活性化に貢献したいとの想いから本サービスを開始いたしました。</p>
        <b>テレワークかとちゃんのイメージ</b>
        <p>・内線で繋がる貴社の社内一部署
          ・抽象度の高い、漠然とした悩みから解決策を考えてくれる、参謀</p>
        <div class="image">
          <img src="../assets/naisen.jpg">
        </div>
        <p>一言で言えば、 「埼玉をもっと最高な場所にしたい。」 「そのために埼玉のみんなが困っていることの力になりたい。」 という代表の熱い気持ちに端を発したサービスです。</p>
        <b>ご利用料金</b>
        <p>お電話の段階では金銭は発生いたしません。 新型コロナウイルスが落ち着いた暁には、 ぜひお仕事をご一緒いたしましょう。</p>
        <p>※具体的な導入支援など、弊社メンバーが稼働する段階より、業務量に応じて別途ご相談させてください。</p>
        <div class="image">
          <a href="tel:080-3490-5892"><img src="../assets/tellme.png"></a>
        </div>
        <b>かとちゃんからの言葉</b>
        <p>プレスリリースを公開しました5月14日は自身の誕生日でもあり、今年で30歳を迎えることとなりました。また、会社の設立も2019年5月14日であり、ちょうど一周年を迎えることとなります。この混沌とした世の中を好機と捉え埼玉県の皆さまと新たな時代をつくっていく、本日がそのような動きが始まった第三の誕生日になることを願っております。</p>
      </div>
    </div>
    <div class="social-container">
      <div class="share"><b>SHARE</b></div>
        <div class="sns-items">
          <social-sharing url="https://studiosaitama.com/telework"
            title="埼玉県内事業者のテレワーク導入支援を電話一本、無料で行う新サービス「テレワークかとちゃん」"
            description="本サービスは、記載されている弊社代表加藤の個人携帯番号に電話するだけで、いつでも（埼玉県内の）誰でもテレワークに関する質問をすることができる直通相談サービスです。"
            quote="本サービスは、記載されている弊社代表加藤の個人携帯番号に電話するだけで、いつでも（埼玉県内の）誰でもテレワークに関する質問をすることができる直通相談サービスです。"
            hashtags="テレワーク導入支援"
            twitter-user="illhhd"
            inline-template>
              <div>
                <network network="twitter">
                  <img style="max-width:31px;" src="../assets/tw.png"/>
                </network>
                <network network="facebook">
                  <img style="max-width:31px;" src="../assets/fb.png" />
                </network>
              </div>
          </social-sharing>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'テレワークかとちゃん | スタジオサイタマ合同会社',
    meta: [
      { name: 'keywords', content: 'テレワーク,埼玉,無料相談,スタジオサイタマ' },
      { name: 'description', content: '埼玉県内事業者のテレワーク導入支援を無料で行う新サービス「テレワークかとちゃん」' },
      { property: 'og:title', content: 'テレワークかとちゃん | スタジオサイタマ合同会社' },
      { property: 'og:description', content: '埼玉県内事業者のテレワーク導入支援を無料で行う新サービス「テレワークかとちゃん」' },
      { property: 'og:site_name', content: 'テレワークかとちゃん | スタジオサイタマ合同会社' },
      { property: 'og:url', content: 'https://studiosaitama.com/telework' },
      { property: 'og:image', content: 'https://studiosaitama.com/static/telework-og.png' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'テレワークかとちゃん | スタジオサイタマ合同会社' },
      { name: 'twitter:url', content: 'https://studiosaitama.com/telework' },
      { name: 'twitter:description', content: '埼玉県内事業者のテレワーク導入支援を無料で行う新サービス「テレワークかとちゃん」' },
      { name: 'twitter:image', content: 'https://studiosaitama.com/static/telework-og.png' }
    ]
  }
}
</script>

<style scoped>
.wrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.article-container {
  padding: 7%;
}

.article-container .caption {
  font: 14px/24px Noto Sans JP;
  padding-bottom: 6px;
}

.article-container .title {
  font: Bold 22px/30px Noto Sans JP;
  border-bottom: 1px solid black;
  padding-bottom: 15px;
}

.article-container .content {
  font: Normal 15px/26px Noto Sans JP;
}

.content {
  padding-top: 10px;
}

.content .image {
  text-align: center;
  max-width: 355px;
  margin-left: auto;
  margin-right: auto;
}

.content img {
  padding-bottom: 17px;
}

img {
  width: 100%;
}

.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share {
  font-weight: bold;
  border-bottom: 2px solid black;
}
.sns-items {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
