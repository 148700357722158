<template>
  <div class="wrapper">
    <div class="image">
      <img src="../assets/wazao.jpg">
    </div>
    <div class="article-container">
      <div class="caption">オンラインストア「WAZAO-IPPON」がオープン
      </div>
      <h1 class="title">埼玉県の伝統産業である竹釣竿「和竿」を中心とした事業「WAZAO-IPPON（ワザオイッポン）」を立ち上げました</h1>
      <div class="content txt">
        <b>WAZAO-IPPONオンラインストア</b>
        <p>
          <a href="https://wazao-ippon.com/" target="blank">https://wazao-ippon.com/</a>
        </p>
        <b>◆日本独自の伝統釣竿「和竿」について</b>
        <p>和竿とは、主に竹を素材とし、絹糸や漆をあしらうことで機能的かつ美術的な装飾を兼ね備えた日本独自の釣竿です。</p>
        <p>埼玉県川口市の伝統産業である「和竿」の職人と行う、オリジナル竹釣竿作り体験</p>
        <p>昨今はグラスファイバーやカーボンロッドの普及に伴い需要が減少傾向にありますが、100%自然由来の素材を活用した釣りのスタイルや竹ならではの釣り味は、今もなお多くのファンを魅了し続けています。</p>
        <p>和竿文化の歴史は古く、江戸時代の庶民文化として花開き、その技法は現在まで受け継がれてきました。特に埼玉県川口エリアは当時から数多くの和竿師を抱えており、最盛期には国内の和竿の約90%を製造するなど、名実ともに地域の一大産業を担っていました。</p>
        <div class="image">
          <img src="../assets/wazao_01.jpg">
        </div>
        <b>◆WAZAO-IPPONについて</b>
        <p>WAZAO-IPPONの使命 は『釣りの新たな魅力発見のきっかけを作る』ことです。</p>
        <p>趣味で行う釣りは、その目的が「漁労」とは異なります。その魅力は「魚を捕まえる」こと以上に、釣りを通じて自然と向き合い続ける時間や体験にこそあると信じています。</p>
        <p>昨今の釣具は新素材や仕掛けの進化に伴い、機能性・利便性が向上しています。このことは釣るという行為を効率化する一方で、釣りそのものや自然と向き合う機会を削ぎ落とす側面もあるのではないかと考えました。そのことが本事業の始まりでした。</p>
        <p>このような背景に対して、WAZAO-IPPONは日本の伝統的な釣具「和竿」を提案します。</p>
        <p>WAZAO-IPPONは「和竿」を通じて、釣りの持つ魅力を広げることに挑戦していきます。</p>
        <p>また、釣りの多面的な魅力を広めることによって、釣りを楽しむ人々がさらに増え、日本の釣り文化そのものが発展していくことを願っています。</p>
        <div class="image">
          <img src="../assets/wazao_02.jpg">
        </div>
        <b>◆オンラインストアについて</b>
        <p>1月15日にオープンするストアは、業界では珍しい和竿のみを取り扱う店舗です。</p>
        <p>販売する和竿は山野和竿店の山野正幸氏の監修の元、厳選した商品を選定しています。WAZAO-IPPONで取り扱う和竿はいずれも国内産の竹を使用し、職人の確かな技術で生み出された和竿を揃えています。</p>
        <p>また、既製品の和竿の販売のみならず、ご自宅の和竿修理やオーダーメイドによる好みの竿の製作のほか、素材販売のご要望にも対応いたします。</p>
        <p>今後は新しい和竿商品の製造や関連アイテムの追加などの商品ラインナップ拡充予定です。和竿や釣りの魅力を発信する情報も続々とリリースいたします。</p>
        <div class="image">
          <img src="../assets/wazao_03.jpg">
        </div>
        <b>◆埼玉県の伝統資産である、和竿や釣り文化の更なる発展を目指して</b>
        <p>スタジオサイタマでは「釣り文化」を埼玉県における重要産業と位置付けています。</p>
        <p>理由として、埼玉県は、<br>・江戸を中心とした釣り文化を支えた和竿の生産地である<br>・荒川や利根川に代表される関東屈指の大型河川がある<br>・県土に占める河川面積割合が日本一であり、水辺空間が豊富な「川の県」である<br>・国内屈指の釣具メーカー「上州屋」や「マルキュー」本社をはじめ、日本最古のリールメーカーともいわれる「五十鈴工業」（※2020年移転）など多くの釣り関連企業が所在する<br>などの事実に加え、県内外へのアクセスの良さから海・山・川全てのジャンルの釣り文化が深く根付いていることが挙げられます。</p>
        <p>また、昨今のアウトドアブームや河川敷で行うテントサウナのブームなど、自然体験型アクティビティの潮流拡大や新型コロナウイルス感染拡大によるソーシャルディスタンスの確保などの問題と釣りの親和性は高く、今後大きなニーズが見込まれると考えています。</p>
        <p>以上を踏まえ、スタジオサイタマでは弊社メンバーの豊富な企画力や遊休資産活用ノウハウ、体験提供実績などを元に、埼玉県発の新しい釣りスタイルの発信や釣りとの接点の創出を行ってまいります。</p>
        <div class="image">
          <img src="../assets/wazao_04.jpg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: '日本独自の伝統釣竿「和竿」に特化したオンラインストア「WAZAO-IPPON」 | スタジオサイタマ合同会社',
    meta: [
      { name: 'keywords', content: '和竿,和竿一本,wazao,ippon,wazaoippon,釣り,埼玉,伝統文化,スタジオサイタマ' },
      { name: 'description', content: '日本の伝統文化であり、埼玉県の伝統産業である竹釣竿「和竿」を中心とした事業「WAZAO-IPPON（ワザオイッポン）」を立ち上げました。合わせてオンラインストアをオープンいたしました。' },
      { property: 'og:title', content: '日本独自の伝統釣竿「和竿」に特化したオンラインストア「WAZAO-IPPON」 | スタジオサイタマ合同会社' },
      { property: 'og:description', content: '日本の伝統文化であり、埼玉県の伝統産業である竹釣竿「和竿」を中心とした事業「WAZAO-IPPON（ワザオイッポン）」を立ち上げました。合わせてオンラインストアをオープンいたしました。' },
      { property: 'og:site_name', content: '日本独自の伝統釣竿「和竿」に特化したオンラインストア「WAZAO-IPPON」 | スタジオサイタマ合同会社' },
      { property: 'og:url', content: 'https://studiosaitama.com/wazao' },
      { property: 'og:image', content: 'https://studiosaitama.com/static/wazao.jpg' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: '日本独自の伝統釣竿「和竿」に特化したオンラインストア「WAZAO-IPPON」 | スタジオサイタマ合同会社' },
      { name: 'twitter:url', content: 'https://studiosaitama.com/wazao' },
      { name: 'twitter:description', content: '日本の伝統文化であり、埼玉県の伝統産業である竹釣竿「和竿」を中心とした事業「WAZAO-IPPON（ワザオイッポン）」を立ち上げました。合わせてオンラインストアをオープンいたしました。' },
      { name: 'twitter:image', content: 'https://studiosaitama.com/static/wazao.jpg' }
    ]
  }
}
</script>

<style scoped>
.wrapper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.article-container {
  padding: 7%;
}
.article-container .caption {
  font: 14px/24px Noto Sans JP;
  padding-bottom: 6px;
}
.article-container .title {
  font: Bold 22px/30px Noto Sans JP;
  border-bottom: 1px solid black;
  padding-bottom: 15px;
}
.article-container .content {
  font: Normal 15px/26px Noto Sans JP;
}
.content {
  padding-top: 10px;
}
.content .video {
  text-align: center;
}
.content img {
  padding-bottom: 17px;
}
img {
  width: 100%;
}
.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.share {
  font-weight: bold;
  border-bottom: 2px solid black;
}
.sns-items {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
