<template>
  <footer class="footer">
    <div class="container footer-contrainer">
      <div class="row">
        <div class="col-sm-4 col-md-4 col-12 col">
          <ul class="navbar-nav menu-list">
            <li class="footer-item" v-for="(item, index) in items" :key=index>
              <router-link class="nav-link" v-bind:to=item.path>
                  {{ item.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <router-link to="/">
        <p class="copyright">©️STUDIOSAITAMA LLC.</p>
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      items: [
      ]
    }
  }
}
</script>

<style scoped>
.footer-contrainer {
  max-width: 90%;
}
.footer-item a {
  color: #FFFFFF;
  font: normal 16px/12px Source Han Sans JP;
}
.copyright {
  height: auto;
  line-height: 1;
  margin-top: 13px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}
.col_white_amrc {
  color: #FFFFFF;
  font: bold 18px/14px Source Han Sans JP;
  padding-top:40px;
  margin-bottom:20px;
}
footer {
  width:100%;
  background-color: #0A2740;
  padding:10px 0px 25px 0px;
}
footer p {
  font-size:16px;
  font: normal 16px/12px Source Han Sans JP;
  color: #FFFFFF;
  line-height: normal;
  padding-bottom:0px;
  margin-bottom:8px;
}
.nav-link {
  padding: 0;
}
.corp-info {
  display: flex;
  align-items: flex-end;
}
</style>
